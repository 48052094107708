@font-face {
    font-family: 'ChiselBold';
    src: url('/src/fonts/Chisel Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.partners-page-container {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100vh;
    overflow: hidden;
    padding-top: 7rem; /* Increased padding to prevent overlap with the fixed navbar */
}

.video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.video-background video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-overlay {
    position: relative;
    z-index: 1;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.3); /* Semi-transparent background for better readability */
    margin-top: 2rem;
}

.content-box, .info-box {
    background: rgba(255, 255, 255, 0.8); /* Reduced transparency for better readability */
    padding: 1rem;
    border-radius: 8px;
    margin-top: 1rem; /* Add margin to separate boxes */
}

.partners-header-title {
    font-family: 'ChiselBold', sans-serif; /* Apply Chisel Bold font */
    background: linear-gradient(to right, #006400, #32CD32, #006400);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: gradientShift 8s ease-in-out infinite;
    text-align: center;
    font-weight: bold;
    margin-top: 3rem;
    font-size: 2.5rem; /* Adjust font size */
}

.email-link {
    color: #32CD32; /* Add color to email link */
    font-weight: bold; /* Make the email link bold */
    text-decoration: none; /* Remove underline */
}

.email-link:hover {
    text-decoration: underline; /* Underline on hover */
}

@media (max-width: 768px) {
    .partners-header-title {
        font-size: 2rem; /* Reduce font size for smaller screens */
    }

    .content-box, .info-box {
        padding: 0.5rem; /* Adjust padding for smaller screens */
    }

    .email-link {
        font-size: 1rem; /* Adjust font size for smaller screens */
    }
}

/* Keyframe animation for the text gradient effect */
@keyframes gradientShift {
    0%, 100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
}
