.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed; /* Make the navbar fixed */
    top: -10px; /* Adjust this value to move the navbar higher if needed */
    width: 100%;
    background-color: black; /* Ensure a consistent background color */
    padding: 1rem 2rem; /* Add some padding for better spacing */
    z-index: 10; /* Ensure it stays above other content */
}

.navbar-logo-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.logo-default {
    width: 70px; /* The default logo size */
    height: auto; /* Maintain aspect ratio */
    transition: opacity 0.5s ease-in-out;
}

.logo-hover {
    width: 500px; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    position: absolute;
    top: 50%; /* Position it at the center vertically */
    left: 50%; /* Position it at the center horizontally */
    transform: translate(-50%, -50%); /* Center the image both vertically and horizontally */
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.7)); /* Add a shadow for better readability */
}

.navbar-logo-container:hover .logo-default {
    opacity: 0;
}

.navbar-logo-container:hover .logo-hover {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.5); /* Enlarge on hover while keeping it centered */
}

.navbar ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    list-style: none; /* Remove bullet points */
    margin: 0;
    padding: 0;
    margin-left: 7rem; /* Adjust this value to move the items to the right */
}

.navbar ul li {
    margin: 0 10px;
}

.navbar button {
    font-size: 1.25rem;
    margin: 0 10px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.navbar button:hover {
    color: #32CD32;
}

.apply-button {
    background: black; /* Set the background to black */
    border: none;
    cursor: pointer;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 8px;
    margin-left: 1rem; /* Add some spacing between the Partners link and the button */
    text-transform: uppercase; /* Uppercase text for style */
    display: inline-block; /* Ensure the anchor behaves like a button */
    text-align: center; /* Center the text */
    color: white; /* Ensure the text color is white */
}

.apply-button-text {
    background: linear-gradient(to right, #006400, #32CD32, #006400);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    color: white; /* Change text color to white */
    animation: gradientShift 8s ease-in-out infinite;
    font-size: 1.25rem; /* Increase font size */
    font-weight: bold; /* Make the text bold */
}

/* Media queries for responsive design */
@media (max-width: 768px) {
    .navbar {
        padding: 0.5rem 1rem; /* Reduce padding for smaller screens */
    }

    .navbar ul {
        margin-left: 1rem; /* Adjust margin for smaller screens */
    }

    .navbar button {
        font-size: 1rem;
        margin: 0 5px;
    }

    .apply-button {
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
    }

    .apply-button-text {
        font-size: 1rem;
    }

    .logo-hover {
        width: 200px; /* Adjust size for smaller screens */
        transform: translate(-50%, -50%) scale(1.2); /* Slightly enlarge on hover for smaller screens */
    }
}

/* Keyframe animation for the text gradient effect */
@keyframes gradientShift {
    0%, 100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
}