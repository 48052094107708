.terms-of-use-container {
    position: relative;
    width: 100%;
    min-height: 100vh; /* Ensure the container takes up the full viewport height */
    background-color: #f5f5f5;
    padding: 20px;
    padding-top: 100px; /* Increased padding to push content further down */
}

.content-overlay {
    max-width: 1200px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.policy-header-title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    font-family: 'ChiselBold', sans-serif;
    background: linear-gradient(90deg, #006400, #32cd32, #006400);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: gradientShift 5s ease-in-out infinite;
}

.policy-content-box h2 {
    font-size: 1.75rem;
    margin-top: 1.5rem;
    font-weight: bold;
}

.policy-content-box p, .policy-content-box ul {
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 1.5rem;
}

.policy-content-box ul {
    list-style-type: disc;
    padding-left: 2rem;
}

/* Keyframe animation for the text gradient effect */
@keyframes gradientShift {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
