/* Global styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  color: #333;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  flex: 1; /* This ensures the content area grows to fill the available space */
  padding-bottom: 60px; /* Ensure there is enough space at the bottom so the content doesn't overlap the footer */
}

/* Fade-in and fade-out transitions */
.fade-in {
  opacity: 1;
  transition: opacity 0.75s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.75s ease-out;
}

/* Home Page Video Background */
.homepage-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Ensure the container is full height */
  overflow: hidden;
}

.homepage-container .video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Additional styles */

@keyframes gradientShift {
  0%, 100% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
      animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.animated-gradient {
  background: linear-gradient(to right, #006400, #32CD32, #006400);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradientShift 8s ease-in-out infinite;
  text-align: center;
  font-weight: bold;
}

.headline-container {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: italic;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 10;
}

.overlay-list .list-item {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  font-size: 1.5rem;
}

.overlay-list .icon {
  font-size: 1.75rem;
}

.services-page .list-item {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  font-size: 1.5rem;
}

.services-page .icon {
  font-size: 1.75rem;
}

.homepage-container .animated-gradient {
  font-size: 2rem;
  font-weight: extrabold;
  text-align: center;
  width: 100%;
}

@media (max-width: 480px) {
  .navbar ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
  }

  .navbar ul li {
      margin: 5px 0;
  }

  .navbar button {
      font-size: 1rem;
  }

  .headline-container {
      top: 3%;
  }

  .impress-capital {
      font-size: 1.75rem;
  }

  .headline-subtext {
      font-size: 1.25rem;
      margin-bottom: 1rem;
  }

  .overlay-list .list-item {
      font-size: 1rem;
      margin: 0.25rem 0;
  }

  .overlay-list .icon {
      font-size: 1.25rem;
  }

  .homepage-container .video-background {
      width: 100%;
      height: 100vh;
      max-height: 100vh;
  }

  .homepage-container video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .navbar ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
  }

  .navbar ul li {
      margin: 5px 10px;
  }

  .navbar button {
      font-size: 1.25rem;
  }

  .headline-container {
      top: 4%;
  }

  .impress-capital {
      font-size: 2rem;
  }

  .headline-subtext {
      font-size: 1.5rem;
      margin-bottom: 1rem;
  }

  .overlay-list .list-item {
      font-size: 1.25rem;
      margin: 0.5rem 0;
  }

  .overlay-list .icon {
      font-size: 1.5rem;
  }

  .homepage-container .video-background {
      width: 100%;
      height: 100vh;
      max-height: 100vh;
  }

  .homepage-container video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .navbar ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
  }

  .navbar ul li {
      margin: 5px 15px;
  }

  .navbar button {
      font-size: 1.5rem;
  }

  .headline-container {
      top: 5%;
  }

  .impress-capital {
      font-size: 2rem;
  }

  .headline-subtext {
      font-size: 1.75rem;
      margin-bottom: 1rem;
  }

  .overlay-list .list-item {
      font-size: 1.5rem;
      margin: 0.5rem 0;
  }

  .overlay-list .icon {
      font-size: 1.75rem;
  }

  .homepage-container .video-background {
      width: 100%;
      height: 100vh;
      max-height: 100vh;
  }

  .homepage-container video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
  }
}

.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 2rem;
}

.header-title {
  background: linear-gradient(to right, #006400, #32CD32, #006400);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradientShift 8s ease-in-out infinite;
  text-align: center;
  font-weight: bold;
  margin-top: 3rem;
}

.text-content {
  text-align: center;
}

.apply-button {
  background: linear-gradient(to right, #006400, #32CD32, #006400);
  background-size: 200% 100%;
  color: white;
  animation: gradientShift 8s ease-in-out infinite;
  border: none;
  cursor: pointer;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 8px;
}

.apply-button:hover {
  opacity: 0.9;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

body {
  font-family: 'Roboto', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
