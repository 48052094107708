/* Import the Chisel Bold font */
@font-face {
    font-family: 'ChiselBold';
    src: url('/src/fonts/Chisel Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.homepage-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Ensure the container takes up the full viewport height */
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4)); /* White gradient background */
    margin-top: 90px;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Place video in the background */
}

.video-background video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure video covers the entire area */
}

.headline-container {
    position: absolute;
    top: 20%; /* Move it up by reducing the percentage */
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 10;
    padding-top: 0px; /* Padding to maintain spacing */
    margin-top: 0; /* Adjust or remove margin if necessary */
}

.headline-subtext {
    font-family: 'ChiselBold', sans-serif;
    font-size: 2.5rem !important;
    font-weight: bold;
    font-style: normal;
    background: linear-gradient(90deg, gold, white, gold);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: gradientShift 5s ease-in-out infinite;
    margin-bottom: 1rem;
}

.transparent-list-container {
    background-color: rgba(0, 0, 0, 0.3); /* Lighter transparent background */
    padding: 20px; /* Padding around the list */
    border-radius: 8px; /* Rounded corners */
    width: 100%; /* Ensure it spans the full width */
    max-width: 800px; /* Set a max-width if you want it to have some limits */
    margin: 0 auto; /* Center the container */
}

.overlay-list {
    margin: 0; /* Remove any default margin */
    padding: 0; /* Remove any default padding */
    text-align: center;
}

.overlay-list .list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;
    font-size: 1rem;
}

.overlay-list .icon {
    font-size: 1.25rem;
}

/* Keyframe animation for the gradient text shift */
@keyframes gradientShift {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
} 
