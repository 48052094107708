.footer {
  position: fixed; 
  bottom: 0; 
  width: 100%; 
  background-color: black; 
  color: white; 
  padding: 10px 0; 
  z-index: 1000; 
}

.footer-content {
  display: flex;
  justify-content: space-between; /* Space between left and right content */
  align-items: center;
  padding: 0 20px; /* Padding on sides */
}

.footer-left {
  text-align: left;
}

.footer-right {
  text-align: right;
}

.footer-link {
  background: none;
  border: none;
  color: white;
  text-decoration: none;
  cursor: pointer;
  margin-left: 15px; /* Spacing between links */
  font-weight: normal; /* Ensure links are not bold */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.footer-link:hover {
  color: #32CD32; /* Green color on hover */
  text-decoration: underline;
}