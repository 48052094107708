@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'HindSiliguri';
    src: url('./fonts/HindSiliguri-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

body {
    font-family: 'Roboto', sans-serif;
}
